import { createGlobalStyle, css } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    color: ${ ({ theme }) => theme.colors.black };
    font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` }, Sans-Serif;
    font-feature-settings: 'ss01' on, 'ss09' on, 'ss10' on;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.45px;
  }

  main {
    @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md1 }) {
      min-height: calc(100vh - 638px);
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
a {
    position: relative;
    color: ${ ({ theme }) => theme.colors.black };
    font-family: ${ ({ theme }) => `${ theme.fonts.GTAmericaRegular }` }, Sans-Serif;
    text-decoration: none;
    transform: none;
    transition: none;

@media screen and (prefers-reduced-motion: reduce) {
&::after {
      position: absolute;
      bottom: -0.23rem;
      left: 0;
      width: 0;
      height: 0.1em;
      content: '';
      background-color: ${ ({ theme }) => theme.colors.black };
      transition: none;
      opacity: 0;
}
}

    &::after {
      position: absolute;
      bottom: -0.23rem;
      left: 0;
      width: 0;
      height: 0.1em;
      content: '';
      background-color: ${ ({ theme }) => theme.colors.black };
      transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
      opacity: 0;
    }

    &:hover::after, &:focus::after {
      width: 100%;
      opacity: 1;
    }
}
}

  a {
    position: relative;
    color: ${ ({ theme }) => theme.colors.black };
    font-family: ${ ({ theme }) => `${ theme.fonts.GTAmericaRegular }` }, Sans-Serif;
    text-decoration: none;
    transform: none;
    transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

@media screen and (prefers-reduced-motion: reduce) {
&::after {
      position: absolute;
      bottom: -0.23rem;
      left: 0;
      width: 0;
      height: 0.1em;
      content: '';
      background-color: ${ ({ theme }) => theme.colors.black };
      transition: none;
      opacity: 0;
}
}

    &::after {
      position: absolute;
      bottom: -0.23rem;
      left: 0;
      width: 0;
      height: 0.1em;
      content: '';
      background-color: ${ ({ theme }) => theme.colors.black };
      transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
      opacity: 0;
    }

    &:hover::after, &:focus::after {
      width: 100%;
      opacity: 1;
    }
  }

  ul {
    padding: 0 0 0 20px;
  }

  .sup {
    font-size: smaller;
    vertical-align: super;
  }
`

export const generalButtonStyle = css`
  display: inline-block;
  padding: 14px 18px;
  color: ${ ({ theme }) => theme.colors.black };
  font-family: ${ ({ theme }) => theme.fonts.InterMedium };
  font-size: 14.5px;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  border-radius: 45px;
  transition: all 0.25s;
  cursor: pointer;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: fit-content;
    padding: 22px 32px;
    font-size: 17.5px;
  }

  &:hover,
  &:focus {
    &::after {
      content: none;
    }
  }
`

export const flamingoButtonStyle = css`
  ${ generalButtonStyle }

  background: ${ ({ theme }) => theme.colors.flamingo[2] };
  border: 1px solid ${ ({ theme }) => theme.colors.flamingo[2] };

  &:hover, &:focus {
    background: ${ ({ theme }) => theme.colors.flamingoDark };
    border-color: ${ ({ theme }) => theme.colors.flamingoDark };
  }
`

export const azureButonStyle = css`
  ${ generalButtonStyle }

  background: ${ ({ theme }) => theme.colors.azure[2] };
  border: 1px solid ${ ({ theme }) => theme.colors.azure[2] };

  &:hover, &:focus {
    background: ${ ({ theme }) => theme.colors.azure[1] };
    border-color: ${ ({ theme }) => theme.colors.azure[1] };
  }
`

export const whiteButtonStyle = css`
    ${ generalButtonStyle }

    background: ${ ({ theme }) => theme.colors.white };
    border: none;

    &:hover, &:focus {
      color: ${ ({ theme }) => theme.colors.white };
      background-color: ${ ({ theme }) => theme.colors.black };
      border-color: ${ ({ theme }) => theme.colors.black };
    }
`

export const poppyButtonStyle = css`
    ${ generalButtonStyle }

    background: ${ ({ theme }) => theme.colors.poppy[3] };
    border: none;

    &:hover, &:focus {
      color: ${ ({ theme }) => theme.colors.white };
      background-color: ${ ({ theme }) => theme.colors.black };
      border-color: ${ ({ theme }) => theme.colors.black };
    }
`

export const anchorStyle = css`
  font-family: ${ ({ theme }) => theme.fonts.InterMedium };
  font-size: 14.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-decoration-line: underline;

  &:hover,
  &:focus {
    &::after {
      content: none;
    }
  }
`
