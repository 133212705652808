import PropTypes from 'prop-types'
import styled from 'styled-components'

const CloseButton = (props) => {
  const { className, onClick, testID } = props

  return <CloseButtonBase className={className} onClick={onClick} data-testid={testID} />
}

const CloseButtonBase = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 0.6;

  &:hover, &:focus {
    opacity: 1;
  }

  &::before,
  &::after {
    position: absolute;
    left: 15px;
    width: 2px;
    height: 33px;
    content: ' ';
    background-color: white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

CloseButton.defaultProps = {
  testID: '',
}

CloseButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  testID: PropTypes.string,
}

export default CloseButton
