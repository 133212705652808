import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Button from '../Button'
import CloseButton from '../CloseButton'

import { serializePressUrl } from '../../utils/urlSerializer'

import MobileDrawerItem from './MobileDrawerItem'

const MobileDrawer = (props) => {
  const {
    isDrawerOpen,
    drawerVisibilityHandler,
    dropdownLinks,
    authenticatedLoanOptions,
    authenticatedLeadOptions,
    hasLoan,
    hasLead,
  } = props

  return (
    <>
      <StyledBurger
        onClick={drawerVisibilityHandler}
        data-testid="menu-button-mobile-drawer"
        aria-label="Menu"
      >
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
      </StyledBurger>

      <BackdropWrapper data-testid="mobile-drawer" isDrawerOpen={isDrawerOpen}>
        <Link href="/" passHref>
          <Anchor>
            <Logo src="/hm-logo.svg" alt="HM Logo" width="243" height="34" />
          </Anchor>
        </Link>
        <BackdropHeader>
          <StyledCloseButton onClick={drawerVisibilityHandler} testID="close-mobile-drawer" />
        </BackdropHeader>
        <BackdropContent>
          {dropdownLinks?.map((options, index) => (
            <MobileDrawerItem
              key={`mobile-header-dropdown-link-${ index.toString() }`}
              testID={`mobile-title-${ serializePressUrl(options.primary.dropdown_text) }`}
              options={options}
            />
          ))}
        </BackdropContent>
        <Hr />

        {!hasLoan && !hasLead ?
          (
            <LoginWrapper>
              <Link href={`${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_URL }/apply/auth/login`} passHref>
                <CenterLink data-testid="mobiledrawer-log-in">Log in</CenterLink>
              </Link>
              <CheckMyRateBtn />
            </LoginWrapper>
          ) :
          (
            <div>
              {hasLoan && (
                <>
                  <Link href={authenticatedLoanOptions[0].link_href} passHref>
                    <OptionLink data-testid="mobiledrawer-loan-option">
                      {authenticatedLoanOptions[0].link_text}
                    </OptionLink>
                  </Link>
                  <Hr />
                  <Link href={authenticatedLoanOptions[1].link_href} passHref>
                    <CenterLink data-testid="mobiledrawer-log-out">
                      {authenticatedLoanOptions[1].link_text}
                    </CenterLink>
                  </Link>
                </>
              )}
              {hasLead && (
                <>
                  <Link href={authenticatedLeadOptions[0].link_href} passHref>
                    <OptionLink data-testid="mobiledrawer-lead-option">
                      {authenticatedLeadOptions[0].link_text}
                    </OptionLink>
                  </Link>
                  <Hr />

                  <Link href={authenticatedLeadOptions[1].link_href} passHref>
                    <CenterLink data-testid="mobiledrawer-log-out">
                      {authenticatedLeadOptions[1].link_text}
                    </CenterLink>
                  </Link>
                </>
              )}
            </div>
          )}
      </BackdropWrapper>
    </>
  )
}

const LinkStyle = css`
  color: ${ ({ theme }) => theme.colors.white };
  text-decoration: none;

  ::after {
    content: none;
  }
`

const BurgerLine = styled.div`
  position: relative;
  width: 2rem;
  height: 0.25rem;
  background: ${ (props) => props.theme.colors.black };
  border-radius: 10px;
  transition: all 0.3s linear;

  &:nth-child(2) {
    margin: 3px 0;
  }
`

const StyledBurger = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    display: none;
  }
`

const BackdropWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px 24px;
  overflow-y: scroll;
  background: ${ ({ theme }) => theme.colors.black };
  transition: opacity 300ms ease, visibility 300ms ease;
  opacity: 0;
  visibility: hidden;

  ${ ({ isDrawerOpen }) =>
    isDrawerOpen &&
    css`
      opacity: 1;
      visibility: visible;
    ` }
`

const Anchor = styled.a`
  line-height: initial;

  ::after {
    content: none;
  }
`

const Logo = styled(Image)`
  transition: all linear 0.25s;
  filter: invert(1);
`

const BackdropHeader = styled.div`
  justify-content: space-between;
  display: flex;
`

const BackdropContent = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 2rem;
  color: white;
`

const Hr = styled.hr`
  margin: 10px 0;
  border: none;
  border-top: 1px solid ${ ({ theme }) => theme.colors.gray[1] };
`

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  opacity: 1;

  &::before,
  &::after {
    position: absolute;
    left: 15px;
    width: 2px;
    height: 33px;
    content: ' ';
    background-color: ${ ({ theme }) => theme.colors.white };
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

const LoginWrapper = styled.div`
  flex-direction: column;
  display: flex;
  text-align: center;
  gap: 1rem;
`

const CenterLink = styled.a`
  ${ LinkStyle }

  display: block;
  margin-top: 22px;
  font-family: ${ ({ theme }) => `${ theme.fonts.InterMedium }` };
  font-size: 16px;
  line-height: 16px;
  text-align: center;
`

const CheckMyRateBtn = styled(Button)`
  width: 100%;
  color: ${ ({ theme }) => theme.colors.white };
  font-size: 16px;
  background: transparent;
  border: 1px solid ${ ({ theme }) => theme.colors.white };

  :hover,
 :focus {
    color: ${ ({ theme }) => theme.colors.black };
    background: ${ ({ theme }) => theme.colors.white };
    border: 1px solid ${ ({ theme }) => theme.colors.white };
  }
`

const OptionLink = styled.a`
  ${ LinkStyle }

  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-size: 18px;
  line-height: 60px;
`

MobileDrawer.defaultProps = {
  dropdownLinks: [],
}

MobileDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  drawerVisibilityHandler: PropTypes.func.isRequired,
  dropdownLinks: PropTypes.oneOfType([PropTypes.array]),
  authenticatedLoanOptions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  authenticatedLeadOptions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  hasLead: PropTypes.bool.isRequired,
  hasLoan: PropTypes.bool.isRequired,
}

export default MobileDrawer
