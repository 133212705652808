/* global window */
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import { FadeUp } from '@happymoneyinc/move'

import { useAuth } from '../../context/AuthContext'
import { useGlobal } from '../../context/GlobalContext'

import { titleCase } from '../../utils/strings'

import Button from '../Button'
import Banner from '../Banner'

import Dropdown from './Dropdown'
import MobileDrawer from './MobileDrawer'
import Text from '../Text'

const Header = () => {
  const { pathname, query } = useRouter()
  const [isScrolled, setIsScrolled] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isNavShown, setIsNavShown] = useState(false)
  const [{ hasLead = false, hasLoan = false, user: { firstName, applicationPath } = {} }] = useAuth()
  const [{
    header_body: headerBody,
    affiliate_logos: affiliateLogos,
    banner_message: bannerMessage,
    show_banner: showBannerDefault,
    banner_bg_color: bannerBgColor,
    banner_event_info: bannerEventInfo,
  }] = useGlobal()
  const isAffiliatePage = pathname === '/landingpages/[affiliateUID]'
  const isLandingPage = pathname === '/[landingPageUID]' || isAffiliatePage
  const affiliateLogo = isAffiliatePage && affiliateLogos?.filter((logo) => logo.id === query?.affiliateUID)[0]?.affiliate_logo
  const dropdownLinks = headerBody?.filter((el) => el.slice_type === 'header_dropdown')

  const transitionNavBar = () => {
    if (window && window.scrollY > 88) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  const drawerVisibilityHandler = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  useEffect(() => {
    window.addEventListener('scroll', transitionNavBar)

    return () => window.removeEventListener('scroll', transitionNavBar)
  }, [])

  useEffect(() => {
    const landingPath = ['/[landingPageUID]', '/invite', '/buildup', '/giftcard', '/purpose', '/landingpages/[affiliateUID]']
    setIsDrawerOpen(false)

    if (landingPath.includes(pathname)) {
      setIsNavShown(false)
    } else {
      setIsNavShown(true)
    }
  }, [pathname])

  const authenticatedLoanOptions = [
    {
      id: 1,
      link_text: 'My Loan',
      link_href: `${ process.env.NEXT_PUBLIC_HM_LOANS_URL }/member`,
    },
    {
      id: 2,
      link_text: 'Logout',
      link_href: `${ process.env.NEXT_PUBLIC_HM_LOANS_URL }/member/logout?redirect=#${ process.env.NEXT_PUBLIC_HM_LOANS_URL }/member/auth/login`,
    },
  ]

  const authenticatedLeadOptions = [
    {
      id: 1,
      link_text: 'Continue Application',
      link_href: `${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_URL }${
        applicationPath?.path ? applicationPath.path : '/apply/prequal/name'
      }`,
    },
    {
      id: 2,
      link_text: 'Logout',
      link_href: `${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_URL }/apply/logout?redirect=#${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_URL }/apply/auth/login`,
    },
  ]

  return (
    <>
      <StyledHeader className={isScrolled ? 'scrolled' : ''}>
        {showBannerDefault && !isLandingPage &&
          <FadeDown>
            <Banner message={bannerMessage} bannerBgColor={bannerBgColor} bannerEventInfo={bannerEventInfo} />
          </FadeDown>
        }
        <NavbarContainer>
          <Fade>
            <NavbarInner center={!isNavShown && !isAffiliatePage}>
              <Link href="/" passHref>
                <Anchor data-testid="header-logo">
                  <Logo isAffiliatePage={isAffiliatePage} >
                    <Image src="/hm-logo.svg" alt="HM Logo" width="243" height="34" priority />
                  </Logo>
                  <LogoMark isAffiliatePage={isAffiliatePage}>
                    <Image src="/hm-logo-mark-mobile.svg" alt="HM Logo Mark" width="42" height="36" priority />
                  </LogoMark>
                </Anchor>
              </Link>

              <NavGroup isNavShown={isNavShown}>
                {isNavShown && <MainNav>
                  {dropdownLinks?.map((item, index) => (
                    <Dropdown
                      key={`header-dropdown-link${ index.toString() }`}
                      options={item.items}
                      title={item.primary.dropdown_text}
                      href={item.primary.dropdown_href || null}
                      testID={item.primary.dropdown_text}
                      minWidth={'175px'}
                    />
                  ))}
                </MainNav>
                }
                {isNavShown && !hasLoan && !hasLead && (
                  <div>
                    <Link href={`${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_URL }/apply/auth/login`} passHref>
                      <LoginLink data-testid="log-in">Log in</LoginLink>
                    </Link>
                    <StyledButton />
                  </div>
                )}
                {hasLoan && (
                  <Dropdown
                    options={authenticatedLoanOptions}
                    title={titleCase(firstName)}
                    minWidth="210px"
                    align="right"
                  />
                )}
                {hasLead && (
                  <Dropdown
                    options={authenticatedLeadOptions}
                    title={titleCase(firstName)}
                    minWidth="210px"
                    align="right"
                  />
                )}
                {!isNavShown && affiliateLogo &&
                (
                  <AffiliateLogoContainer data-testid="affiliate-logo">
                    <StyledText size="xsmall">THANK YOU FOR VISITING US FROM</StyledText>
                    <AffiliateLogo>
                      <Image src={affiliateLogo.url} width={affiliateLogo.dimensions.width} height={affiliateLogo.dimensions.height} />
                    </AffiliateLogo>
                  </AffiliateLogoContainer>
                )}
              </NavGroup>

              {isNavShown && <MobileDrawer
                drawerVisibilityHandler={drawerVisibilityHandler}
                isDrawerOpen={isDrawerOpen}
                hasLead={hasLead}
                hasLoan={hasLoan}
                authenticatedLoanOptions={authenticatedLoanOptions}
                authenticatedLeadOptions={authenticatedLeadOptions}
                dropdownLinks={dropdownLinks}
              />}

            </NavbarInner>
          </Fade>
        </NavbarContainer>
      </StyledHeader>
    </>
  )
}

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: ${ ({ theme }) => theme.colors.cream };
  border-bottom: 1px solid transparent;
  transition: all 0.25s ease;

  &.scrolled {
    background-color: ${ ({ theme }) => theme.colors.cream };
    border-bottom: 1px solid ${ ({ theme }) => theme.colors.gray[5] };
  }
`

const NavbarContainer = styled.div`
  padding: 0 1rem;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    padding: 0 3rem;
  }
`

const NavbarInner = styled.div`
  align-items: center;
  justify-content: ${ ({ center }) => (center ? 'center' : 'space-between') } ;
  display: flex;
  height: 66px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    justify-content: space-between;
    height: 88px;
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
`

const Anchor = styled.a`
  line-height: initial;

  ::after {
    content: none;
  }
`

const Logo = styled.div`
  display: ${ ({ isAffiliatePage }) => (isAffiliatePage ? 'none' : 'initial') };

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    display: initial;
  }
`

const LogoMark = styled.div`
  display: ${ ({ isAffiliatePage }) => (isAffiliatePage ? 'initial' : 'none') };

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    display: none;
  }
`

const AffiliateLogoContainer = styled.div`
  align-items: center;
  display: flex;
`

const AffiliateLogo = styled.div`
  line-height: initial;

  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    max-width: 139px;
  }
`

const NavGroup = styled.div`
  align-items: center;
  display: ${ ({ isNavShown }) => isNavShown && 'none' };

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    justify-content: ${ ({ isNavShown }) => (isNavShown ? 'space-between' : 'flex-end') };
    display: flex;
    width: 100%;
  }
`

const MainNav = styled.div`
  display: flex;
  height: 88px;
  padding-left: 4vw;

  > div {
    margin-right: 4vw;
  }
`

const LoginLink = styled.a`
  margin-right: 2vw;
  color: ${ ({ theme }) => theme.colors.black };
  font-family: ${ ({ theme }) => `${ theme.fonts.InterMedium }` };
  text-decoration: none;

  ::after {
    content: none;
  }

  :hover,
 :focus {
    color: ${ ({ theme }) => theme.colors.flamingo[1] };
  }
`

const StyledText = styled(Text)`
  margin-right: 16px;
  text-align: right;

  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    width: 87px;
    margin-right: 8px;
  }
`

const StyledButton = styled(Button)`
  padding: 12px 18px;
  font-size: 14px;
  line-height: 125%;
`

const Fade = styled(FadeUp)`
  transform: translate3d(0, 0, 0);
`

const FadeDown = styled(FadeUp)`
  transform: translate3d(0, -2rem, 0);
`

export default Header
