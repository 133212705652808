import { useEffect, useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { logger } from '../utils/logger'

export const AuthContext = createContext({})

export const AuthProvider = (props) => {
  const { children, value } = props

  const [auth, setAuth] = useState({ ...value })

  useEffect(() => {
    let checkSessionInterval

    if (auth?.user?.firstName) {
      checkSessionInterval = setInterval(async () => {
        try {
          const {
            data: { isSessionExpired },
          } = await axios.post(`${ process.env.NEXT_PUBLIC_HM_POINT_BREAK_URL }/api/checksession`)

          if (isSessionExpired) {
            setAuth({})
            clearInterval(checkSessionInterval)
          }
        } catch (error) {
          logger.error('/api/checksession -', {
            message: error
          })
        }
      }, 30000)
    }

    return () => clearInterval(checkSessionInterval)
  }, [])

  return <AuthContext.Provider value={[auth, setAuth]}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)

AuthProvider.defaultProps = {
  value: {},
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  value: PropTypes.objectOf(PropTypes.any),
}

export default {
  AuthContext,
  AuthProvider,
}
