export const icons = {
  arrowRight: {
    title: 'arrowRight',
    height: 13,
    width: 18,
    path: [
      {
        d:
          'M13.3312 13.6388C13.446 10.3093 15.3978 8.35749 18.4977 8.12787V5.94647C15.3978 5.71685 13.446 3.76506 13.3312 0.435547L10.6905 1.0096C10.9201 3.30582 12.6423 5.39538 14.4793 5.94647H0.12793V8.12787H14.4793C12.6423 8.67896 10.9201 10.7685 10.6905 13.0647L13.3312 13.6388Z',
        fill: '#fff'
      }
    ]
  },
  checkmarkNew: {
    title: 'checkmarkNew',
    height: 16,
    width: 16,
    path: [
      {
        d: 'M15.6358 0C9.91667 2.92113 6.89969 7.97324 6.58488 14.1268C6.03395 11.038 3.88272 8.78732 0.91821 8.06901L0 10.3197C3.4892 11.1099 5.14198 13.4803 5.48302 17H8.29012C8.36883 9.4338 11.0185 4.69296 17 2.0831L15.6358 0Z',
        fill: '#fff'
      }
    ]
  },
  openDownCaret: {
    title: 'OpenDownCaret',
    width: 14,
    height: 9,
    path: [
      {
        d: 'M7.078 5.355L11.84.16l1.474 1.352-6.237 6.804L.84 1.51 2.315.159l4.763 5.196z',
        fill: '#242220'
      }
    ]
  },
}

export default icons
