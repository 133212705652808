import styled from 'styled-components'
import Image from 'next/image'

const SecurityLogos = () => (
  <Flex>
    <Image src="/securityLogos/nortonSecured.svg" alt="nortonSecured" width={67} height={28} />
    <Image src="/securityLogos/mcAfee.svg" alt="mcAfee" width={67} height={24} />
    <Image src="/securityLogos/bbb.svg" alt="bbb" width={67} height={26} />
  </Flex>
)

const Flex = styled.div`
  justify-content: start;
  display: flex;
  margin-bottom: 48px;
  gap: 25px;
`

export default SecurityLogos
