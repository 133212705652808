import { useFlags } from 'launchdarkly-react-client-sdk'
import FloatingBanner from '../components/FloatingBanner'

// need to extract it out from _app.js and use useGlobal() to fetch data for prismic preview purpose.
const EmergencyBanner = () => {
  const { flagEmergencyBanner: emergencyBannerFlag } = useFlags()
  const showBannerDefault = !!emergencyBannerFlag?.length

  return (
    showBannerDefault && <FloatingBanner
      bannerMessage={emergencyBannerFlag}
      showBannerDefault={showBannerDefault}
      bannerName={'emergency-banner'}
    />
  )
}

export default EmergencyBanner
