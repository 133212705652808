import Image from 'next/image'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Alert } from '@happymoneyinc/matter'
import { RichText } from 'prismic-reactjs'
import theme from '../theme'
import { textSmallStyles } from '../utils/cmsStyles'

const FloatingBanner = ({ bannerMessage, showBannerDefault, bannerName }) => {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    if (!showBannerDefault) {
      localStorage.removeItem(`${ bannerName }-message`)
    }
    const newMessage = bannerMessage[0]?.text
    if (showBannerDefault && newMessage?.length <= 275) {
      // compare banner message
      const currentMessage = localStorage.getItem(`${ bannerName }-message`)
      if (currentMessage !== newMessage) {
        localStorage.setItem(`${ bannerName }-message`, newMessage)
        localStorage.setItem(`show-${ bannerName }`, 'true')
      }

      if (localStorage.getItem(`show-${ bannerName }`) === 'true') {
        setShowBanner(true)
      }
    }
  }, [showBannerDefault, bannerMessage, bannerName])

  const onClose = () => {
    setShowBanner(false)
    localStorage.setItem(`show-${ bannerName }`, 'false')
  }

  return (
    <>
      {showBanner &&
        <EmergencyBanner dismissible={false}>
          <Message>{RichText.render(bannerMessage)}</Message>
          <CloseButton data-testid="banner-close-button" onClick={onClose}>
            <Image src="/close-button.svg" width={15} height={15} alt="banner close button" />
          </CloseButton>
        </EmergencyBanner>
      }
    </>
  )
}

const EmergencyBanner = styled(Alert)`
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  z-index: 200;
  width: 90vw;
  padding: 10px 12px;
  margin: 8px auto;
  background: white;
  box-shadow: 4px 4px 0 ${ ({ theme }) => theme.colors.poppy[3] };
  border: 1px solid ${ ({ theme }) => theme.colors.black };
  border-radius: 5px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    width: 95vw;
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    padding: 15px 24px;
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    max-width: 827px;
    padding: 18px 24px;
  }

  > div {
    align-items: flex-start;
    width: 100%;
  }

  div > svg {
    margin-top: 1px;
    margin-right: 20px;
  }
`

const Message = styled.div`
  ${ textSmallStyles }

  flex: 2;
  font-family: ${ ({ theme }) => theme.fonts.InterRegular };
  font-size: 10px !important;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    font-size: 12px !important;
  }

  p {
      margin: 0;
  }

  a {
    color: ${ theme.colors.flamingoDarker };
    white-space: nowrap;

    ::after {
      background: ${ theme.colors.flamingoDarker };
    }
  }

  strong {
    font-family: 'inter-bold', Sans-Serif;
  }
`

const CloseButton = styled.button`
  padding: 0;
  margin: 2px 0 0 20px;
  line-height: 1;
  background: none;
  border: none;
`

FloatingBanner.propTypes = {
  bannerMessage: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
  })).isRequired,
  showBannerDefault: PropTypes.bool.isRequired,
  bannerName: PropTypes.string.isRequired,
}

export default FloatingBanner
