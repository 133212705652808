/**
 * Get all the URL params
 * @returns {Object} All query params from URL
 */
export const getUrlParams = () => {
  const params =
        typeof window !== 'undefined' && window.location.search.substring(1)
  // some optimization help -> https://stackoverflow.com/a/8649003
  const parsedParams =
        params &&
        JSON.parse(
          `{"${ params.replace(/&/g, '","').replace(/=/g, '":"') }"}`,
          function decode(key, value) {
            return key === '' ? value : decodeURIComponent(value)
          }
        )

  return parsedParams
}

export const getCheckMyRateUrl = (newFunnelFlag, queryParams = null) => {
  // TODO: create custom route in new funnel to handle redirect logic
  // need to include logged scenario
  const newFunnelUrl = `${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_WEB_URL }/create-account`
  const oldFunnelUrl = `${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_URL }/apply/prequal/name`

  let url = newFunnelFlag ? newFunnelUrl : oldFunnelUrl
  if (queryParams) {
    const parsedParams = queryParams.replace('?', '')
    url = `${ url }?${ parsedParams }`
  }
  return url
}

export default {
  getUrlParams,
  getCheckMyRateUrl,
}
