import Link from 'next/link'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import { checkExternalUrl } from '../../utils/checkExternalUrl'

const MobileDrawerItem = (props) => {
  const { options, testID } = props
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleCollapsed = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <>
      <BackdropMenuTitle onClick={handleCollapsed} data-testid={testID}>
        {options.primary.dropdown_href ?
          (
            <Link href={options.primary.dropdown_href} passHref>
              <BackdropMenuLink>{options.primary.dropdown_text}</BackdropMenuLink>
            </Link>
          ) :
          (
            options.primary.dropdown_text
          )}
        {options.items.length !== 0 && <Chevron isOpen={!isCollapsed} />}
      </BackdropMenuTitle>
      {options.items.map((option, index) => {
        const isExternal = checkExternalUrl(option.link_href)

        return (
          <Option
            className={isCollapsed ? 'collapsed' : ''}
            key={`mobile-header-option-${ index.toString() }`}
            data-testid={`mobile-link-item-${ option.link_text?.toLowerCase().replace(/\s/g, '-') }`}
          >
            <Link href={option.link_href || ''} passHref>
              <BackdropMenuLink
                target={isExternal ? '_blank' : ''}
                rel={isExternal ? 'noreferrer' : ''}
              >
                {option.link_text}
                {isExternal ? ' ↗' : null}
              </BackdropMenuLink>
            </Link>
          </Option>
        )
      })}
    </>
  )
}

const Chevron = styled.i`
  position: relative;
  top: -3px;
  left: 15px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border: solid ${ ({ theme }) => theme.colors.white };
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transform-origin: center;

  ${ ({ isOpen }) =>
    isOpen &&
    css`
      top: 1px;
      border-width: 0 2px 2px 0;
      transform: rotate(-135deg);
    ` }
`

const BackdropMenuTitle = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-size: 18px;
  line-height: 20px;

  :hover, :focus {
    cursor: pointer;
  }
`

const Option = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  max-height: 1000px;
  margin-left: 24px;
  overflow: hidden;
  transition: max-height 1s ease-in-out;

  @media screen and (prefers-reduced-motion: reduce) {
&.collapsed {
    max-height: 0;
    transition: none;
}
}

  &.collapsed {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
`

const BackdropMenuLink = styled.a`
  display: block;
  color: white;
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  cursor: pointer;

  :hover, :focus {
    padding-left: 5px;
  }
`
MobileDrawerItem.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  testID: PropTypes.string.isRequired
}

export default MobileDrawerItem
