import Link from 'next/link'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import DOMPurify from 'isomorphic-dompurify'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { useGlobal } from '../../context/GlobalContext'
import { checkExternalUrl } from '../../utils/checkExternalUrl'

import SecurityLogos from './SecurityLogos'

const Footer = () => {
  const [{
    footer_body: footerBody,
    legal: generalLegal,
    payoff_footer_legal: payoffFooterLegal,
    dm_footer_legal: dmFooterLegal,
    dm_footer_legal_legacy_ver: dmFooterLegacyDesignVer,
    landing_footer_legal: landingFooterLegal,
  }] = useGlobal()
  const [legal, setLegal] = useState([])
  const { pathname, asPath } = useRouter()

  // render different set of legal copy in footer section based on pages
  useEffect(() => {
    const landingPath = ['/invite', '/buildup', '/giftcard', '/landingpages/[affiliateUID]']
    const hmDMList = ['/join', '/happy', '/apply', '/forward', '/journey', '/today', '/save', '/now', '/celebrate']

    if (pathname === '/payoff') {
      setLegal(payoffFooterLegal)
    } else if (landingPath.includes(pathname)) {
      setLegal(landingFooterLegal)
    } else if (pathname === '/[landingPageUID]') {
      if (hmDMList.includes(asPath)) {
        setLegal(dmFooterLegal)
      } else {
        setLegal(dmFooterLegacyDesignVer)
      }
    } else {
      setLegal(generalLegal)
    }
  }, [
    asPath,
    pathname,
    generalLegal,
    payoffFooterLegal,
    dmFooterLegal,
    dmFooterLegacyDesignVer,
    landingFooterLegal,
  ])

  const copyrightLinks = footerBody?.find((el) => el.slice_type === 'footer_copyright_links')

  const socialIcons = [
    {
      href: 'https://www.facebook.com/happymoney/',
      icon: <SocialLogo src="/social/facebook.svg" alt="facebook" width={20} height={20} />,
    },
    {
      href: 'https://www.instagram.com/happymoney/?hl=en',
      icon: <SocialLogo src="/social/instagram.svg" alt="instagram" width={20} height={20} />,
    },
    {
      href: 'https://twitter.com/happymoney',
      icon: <SocialLogo src="/social/twitter.svg" alt="twitter" width={20} height={20} />,
    },
    {
      href: 'https://www.linkedin.com/company/happy-money',
      icon: <SocialLogo src="/social/linkedin.svg" alt="linkedin" width={20} height={20} />,
    },
  ]

  return (
    <StyledFooter>
      <Inner>
        <FlexWrapper>
          <div>
            <Link href="/" passHref>
              <a>
                <Logo src="/hm-logo--white.svg" alt="HM Logo" width="243" height="34" />
              </a>
            </Link>

            <SocialWrapper>
              {socialIcons &&
                socialIcons.map((social, index) => (
                  <Link href={social.href} key={`social-icon-${ index.toString() }`} passHref>
                    <StyledAnchor target="_blank" rel="noreferrer">
                      {social.icon}
                    </StyledAnchor>
                  </Link>
                ))}
            </SocialWrapper>
          </div>

          <LinksWrapper>
            {footerBody &&
              footerBody.map((item, index) => {
                if (item.slice_type === 'footer_links') {
                  return (
                    <NavigationUl key={`footer-body-item-${ index.toString() }`}>
                      {item.items.map((link, index) => {
                        const isExternal = checkExternalUrl(link.link_href)

                        return (
                          <NavigationLi key={`footer-list-item-${ index.toString() }`}>
                            <Link href={link.link_href || ''} passHref>
                              <StyledAnchor
                                target={isExternal ? '_blank' : null}
                                rel={isExternal ? 'noreferrer' : null}
                                data-testid={`footer-link-${ link.link_text
                                .toLowerCase()
                                .replace(/\s/g, '-') }`}
                              >
                                {link.link_text}
                                {isExternal ? ' ↗' : null}
                              </StyledAnchor>
                            </Link>
                          </NavigationLi>
                        )
                      })}
                    </NavigationUl>
                  )
                }
                return null
              })}
          </LinksWrapper>
        </FlexWrapper>
        <Hr />

        <SecurityLogos />

        <LegalCopy
          dangerouslySetInnerHTML={{
            __html: legal ? DOMPurify.sanitize(legal.map((item) => item.text).join(''), { ADD_ATTR: ['target'] }) : '',
          }}
        />

        <BottomWrapper>
          {copyrightLinks?.items.map((link, index) => {
            const isExternal = checkExternalUrl(link.link_href)
            const isNMLS = link.link_href?.includes('NMLS')

            return (
              <Link href={link.link_href} key={`footer-copyright-link-${ index.toString() }`} passHref>
                <CopyrightAnchor
                  target={isExternal ? '_blank' : null}
                  rel={isExternal ? 'noreferrer' : null}
                  data-testid={`footer-copy-${ isNMLS ? 'nmls-consumer-access' : link.link_text.toLowerCase().replace(/\s/g, '-') }`}
                >
                  {link.link_text}
                  {isExternal && !isNMLS ? '↗' : null}
                </CopyrightAnchor>
              </Link>
            )
          })}
          <Copyright>
            © Copyright {new Date().getFullYear()}. All rights reserved. Happy Money, Inc.
          </Copyright>
        </BottomWrapper>
      </Inner>
    </StyledFooter>
  )
}

const Inner = styled.div`
  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
`

const copyrightStyles = css`
  color: ${ ({ theme }) => theme.colors.gray[2] };
  font-family: ${ ({ theme }) => `${ theme.fonts.InterRegular }` };
  font-size: 12px;
`

const StyledAnchor = styled.a`
  color: ${ ({ theme }) => theme.colors.white };
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };

  :hover,
 :focus {
    ::after {
      background-color: ${ ({ theme }) => theme.colors.white };
    }
  }
`

const CopyrightAnchor = styled.a`
  ${ copyrightStyles }

  line-height: 175%;
  text-decoration: underline;

  :hover,
 :focus {
    ::after {
      opacity: 0;
    }
  }
`

const StyledFooter = styled.footer`
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 32px 25px;
  background-color: ${ ({ theme }) => theme.colors.black };

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    padding: 4.063rem 3rem;
  }
`

const BottomWrapper = styled.div`
  align-items: flex-start;
  flex-flow: column wrap;
  justify-content: flex-start;
  display: flex;
  margin: 48px 0 0;
  gap: 4px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    align-items: center;
    flex-flow: row wrap;
    gap: 18px;
  }
`

const FlexWrapper = styled.div`
  flex-flow: column wrap;
  justify-content: space-between;
  display: flex;
  gap: 2rem;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    flex-direction: row;
    gap: 0;
  }
`

const LinksWrapper = styled.div`
  flex-direction: column;
  justify-content: inherit;
  display: flex;
  gap: 1rem;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    flex-basis: 70%;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 5vw;
    padding-left: 5vw;
    gap: 0;
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xl }) {
    padding-right: 100px;
    padding-left: 100px;
  }
`

const NavigationUl = styled.ul`
  flex-direction: column;
  padding: 0;
  margin: 0;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    flex-direction: row;
  }
`

const NavigationLi = styled.li`
  display: block;
  margin-bottom: 5px;
  color: ${ ({ theme }) => theme.colors.white };
`

const SocialWrapper = styled.div`
  display: flex;
  padding-top: 3rem;
  line-height: 0;
  gap: 24px;

  a {
    ::after {
      content: none;
    }
  }
`

const SocialLogo = styled(Image)`
  margin-right: 24px;
`

const Hr = styled.hr`
  width: 100%;
  margin: 48px 0;
  border: 1px solid ${ ({ theme }) => theme.colors.gray[1] };
`

const Logo = styled(Image)`
  margin-bottom: 35px;
  transition: all linear 0.25s;
  filter: none;
`

const LegalCopy = styled.div`
  width: 100%;
  color: ${ ({ theme }) => theme.colors.gray[3] };
  font-family: ${ ({ theme }) => `${ theme.fonts.InterRegular }` };
  font-size: 12px;
  line-height: 175%;

  b {
    font-family: ${ ({ theme }) => `${ theme.fonts.InterBold }` };
  }

  p {
    margin: 0;
  }

  a {
    color: ${ ({ theme }) => theme.colors.flamingo[2] };
    font-family: ${ ({ theme }) => `${ theme.fonts.InterRegular }` };

    :hover,
 :focus {
      ::after {
        background-color: ${ ({ theme }) => theme.colors.flamingo[3] };
      }
    }
  }

  .single-border {
    padding: 16px;
    font-size: 14px;
    border: 1px solid ${ ({ theme }) => theme.colors.gray[1] };

    @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
      padding: 24px 32px;
    }
  }

  .underline {
    text-decoration: underline;
  }
`

const Copyright = styled.p`
  ${ copyrightStyles }

  max-width: 100%;
  min-width: 100%;
  margin: 12px 0 0;
  line-height: 175%;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    margin: -2px 0 0;
  }
`

export default Footer
