import Link from 'next/link'
import PropTypes from 'prop-types'
import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { checkExternalUrl } from '../../utils/checkExternalUrl'

const Dropdown = (props) => {
  const { align, minWidth, href, options, testID, title } = props
  const [isOpen, setIsOpen] = useState(false)

  const dropTitleRef = useRef(null)

  const onKeyUp = (e) => {
    if (e.keyCode === 27) {
      setIsOpen(false)
    }
  }

  const onDropTitleKeyUp = (e) => {
    if (e.keyCode === 13) {
      setIsOpen(true)
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', onKeyUp, false)
    dropTitleRef?.current?.addEventListener('keyup', onDropTitleKeyUp, false)

    return () => {
      document.removeEventListener('keyup', onKeyUp, false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dropTitleRef?.current?.removeEventListener('keyup', onDropTitleKeyUp, false)
    }
  }, [])

  return (
    <Container
      data-testid={testID ? `dropdown-${ testID.toLowerCase().replace(/\s/g, '-') }` : 'dropdown-dynamic'}
    >
      <Link href={href || '#'} passHref>
        <DropTitle data-testid={`dropdown-title-${ title.toLowerCase().replace(/\s/g, '-') }`} onKeyUp={onKeyUp} ref={dropTitleRef}>
          {title} {options.length !== 0 && <Caret />}
        </DropTitle>
      </Link>
      {options.length > 0 && (
        <DropdownContent align={align} minWidth={minWidth} style={{ display: isOpen ? 'block' : null }}>
          {options.map((option, index) => {
            const isExternal = checkExternalUrl(option.link_href)

            return (
              <Link
                href={option.link_href || ''}
                key={`link-item-${ option.link_text?.toLowerCase().replace(/\s/g, '-') }`}
                passHref
              >
                <LinkItem
                  data-testid={`link-item-${ option.link_text?.toLowerCase().replace(/\s/g, '-') }`}
                  target={isExternal ? '_blank' : ''}
                  rel={isExternal ? 'noreferrer' : ''}
                >
                  {option.link_text}
                  {isExternal ? ' ↗' : null}
                </LinkItem>
              </Link>
            )
          })}
        </DropdownContent>
      )}
    </Container>
  )
}

const DropdownContent = styled.div`
  position: absolute;
  top: 72px;
  z-index: 1;
  box-sizing: border-box;
  display: none;
  min-width: ${ ({ minWidth }) => minWidth };
  padding: 18px 0;
  text-decoration: none;
  background-color: ${ ({ theme }) => theme.colors.white };
  box-shadow: 4px 4px 0 ${ ({ theme }) => theme.colors.black };
  border: 1px solid ${ ({ theme }) => theme.colors.black };
  transform: translate(0, -6px);
  transition: all 0.25s ease;

  ${ ({ align }) => {
    if (align === 'left') return 'left: 0;'

    return 'right: 0;'
  } }
`

const DropTitle = styled.a`
  padding: 0 9px 0 0;
  margin: 0;
  color: ${ ({ theme }) => theme.colors.black };
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-size: 16px;
  line-height: initial;
  background-color: inherit;
  transition: all 0.25s ease;
`

const Caret = styled.i`
  position: relative;
  left: 5px;
  display: inline-block;
  padding: 3px;
  border: solid ${ ({ theme }) => theme.colors.black };
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) translateY(-3px);
`

const Container = styled.div`
  position: relative;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  height: 88px;

  &:hover,
 &:focus {
    ${ DropdownContent } {
      display: block;
    }

    ${ DropTitle } {
      color: ${ ({ theme }) => theme.colors.flamingo[1] };
    }

    ${ Caret } {
      border: solid ${ ({ theme }) => theme.colors.flamingo[1] };
      border-width: 0 2px 2px 0;
      transform: rotate(-135deg) translateY(-3px);
    }
  }

  a {
    ::after {
      content: none;
    }
  }
`

const LinkItem = styled.a`
  display: block;
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  text-align: left;
  text-decoration: none;
  cursor: pointer;

  &:hover,
 &:focus {
    background-color: ${ ({ theme }) => theme.colors.gray[5] };
    transform: scale(1);
  }
`

Dropdown.defaultProps = {
  align: 'left',
  minWidth: null,
  href: '',
  testID: null,
}

Dropdown.propTypes = {
  align: PropTypes.string,
  minWidth: PropTypes.string,
  href: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  testID: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Dropdown
