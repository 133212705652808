// eslint-disable-next-line
import happyLogger from '@happymoneyinc/logger'

export const logger = happyLogger({
  level: process.env.HM_LOG_LEVEL, // defaults to info
  ddService: 'point-break', // ddService name where logs will be forwarded on to datadog
  activeEnv: process.env.HM_ACTIVE_ENV,
  ddClientToken: process.env.DD_CLIENT_TOKEN,
})

export default {
  logger,
}
