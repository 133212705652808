import { template, templateSettings } from 'lodash'
import { getPageData } from './getPageData'

/**
 * Interpolate template variables to data object.
 * @param {object} templateVarArr - Arr from prismic that contain template variables
 * @param {object} data - Data that contains template variables.
 * @returns {object} parsed data object that have replaced template variable to actual value
 */
export const interpolate = async (templateVarArr, data) => {
  let templateVarData
  if (!templateVarArr?.length) {
    const { template_variables: defaultTemplateVarsArr } = await getPageData({
      uid: 'template-variables',
    })
    templateVarData = defaultTemplateVarsArr
  } else {
    templateVarData = templateVarArr
  }

  const templateVariables = {}
  templateVarData?.forEach((element) => {
    templateVariables[element.name] = element.value
  })
  templateSettings.interpolate = /{{([\s\S]+?)}}/g
  try {
    const compileData = template(JSON.stringify(data))(templateVariables)
    return JSON.parse(compileData)
  } catch {
    return data
  }
}

export default {
  interpolate
}
