
const internalUrls = (url) => (url.includes('payoff.com') && !url.includes('https://www.happymoney.com'))

const externalUrls = (url) => (url.includes('https://') || url === '/support')

export const checkExternalUrl = (url) => {
  const isExternal = externalUrls(url) && !internalUrls(url)

  return isExternal
}

export default { checkExternalUrl }
