/**
 * Titlecase a string.
 * @param {string} str - The string to titlecase.
 * @returns {string} A string being titlecase
 */
export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ')
  const exceptions = ['a', 'an', 'and', 'as', 'at', 'but', 'by', 'for', 'in', 'the', 'to']

  for (let i = 0; i < splitStr.length; i++) {
    if (i > 0 && exceptions.includes(splitStr[i])) {
      continue
    }
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }

  return splitStr.join(' ')
}

/**
 * Pluralize a string.
 * @param {number} count - The number to determine whether to pluralize the string or not.
 * @param {string} str - The string to pluralize.
 * @returns {string} A string being pluralize if it's needed
 */
export const pluralize = (count, str) => {
  if (count > 1) {
    return str.concat('s')
  }
  return str
}

/**
 * Format special symbol.
 * @param {object} data - Data that contain special symbol †.
 * @returns {object} parsed data object that add class to special symbol
 */
export const formatSymbol = (data) => {
  const string = JSON.stringify(data)
  const stringWithClass = string.replace(/(†)/g, '<span class=sup>†</span>')
  try {
    const parsedData = JSON.parse(stringWithClass)
    return parsedData
  } catch {
    return data
  }
}

export default {
  titleCase,
  pluralize,
  formatSymbol,
}
