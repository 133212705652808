import Link from 'next/link'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAuth } from '../context/AuthContext'

import { azureButonStyle, flamingoButtonStyle, generalButtonStyle, poppyButtonStyle, whiteButtonStyle } from './GlobalStyle'
import { getCheckMyRateUrl } from '../utils/urlHelpers'

const Button = (props) => {
  const { className, buttonContent, buttonHref, testID, color, mobileColor, onClick } = props
  const [href, setHref] = useState('')

  const { flagNewFunnel: newFunnelFlag } = useFlags()

  const [auth] = useAuth()

  const { user: { applicationPath } = {} } = auth

  useEffect(() => {
    if (buttonContent === 'Check my rate') {
      if (auth?.hasLoan) {
        setHref(`${ process.env.NEXT_PUBLIC_HM_LOANS_URL }/member`)
      }

      if (auth?.hasLead) {
        setHref(`${ process.env.NEXT_PUBLIC_HM_SERVICES_APPLY_URL }${ applicationPath?.path ? applicationPath.path : '/apply/prequal/name' }`)
      }
    }
    setHref(getCheckMyRateUrl(newFunnelFlag))
  }, [
    buttonContent,
    auth?.hasLoan,
    auth?.hasLead,
    applicationPath?.path,
    newFunnelFlag
  ])

  const renderButtonContent = () => {
    if (buttonContent === 'Check my rate') {
      if (auth?.hasLoan) {
        return 'Back To My Loan'
      }

      if (auth?.hasLead) {
        return 'Continue Application'
      }
    }

    return buttonContent
  }

  const handleOnClick = (e) => {
    onClick && onClick(e)
  }

  if (buttonHref) {
    return (
      <Link href={buttonHref} passHref>
        <ButtonBase
          className={className}
          data-testid={testID}
          href={buttonHref}
          color={color}
          mobileColor={mobileColor}
        >
          {renderButtonContent()}
        </ButtonBase>
      </Link>
    )
  }

  return (
    <ButtonBase
      className={className}
      data-testid={testID}
      href={onClick ? '' : href}
      onClick={handleOnClick}
      color={color}
      mobileColor={mobileColor}
    >
      {renderButtonContent()}
    </ButtonBase>
  )
}

export const ButtonBase = styled.a`
  ${ ({ color }) => {
    switch (color) {
      case 'white':
        return whiteButtonStyle
      case 'azure':
        return azureButonStyle
      case 'poppy':
        return poppyButtonStyle
      case 'flamingo':
      case '':
      case null:
        return flamingoButtonStyle
      default:
        return css`
          ${ generalButtonStyle }

          background: ${ ({ color }) => color };
          border: 1px solid ${ ({ color }) => color };

          &:hover, &:focus {
            background: ${ ({ color }) => color };
            border-color: ${ ({ color }) => color };
            filter: ${ ({ color }) => (color ? 'brightness(0.85)' : 'none') };
          }
        `
    }
  } }

  ${ ({ mobileColor }) => mobileColor && css`
    @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.md }) {
      background: ${ ({ mobileColor }) => mobileColor };

      &:hover, &:focus {
        background: ${ ({ mobileColor }) => mobileColor };
        border-color: ${ ({ mobileColor }) => mobileColor };
        filter: ${ ({ mobileColor }) => (mobileColor ? 'brightness(0.85)' : 'none') };
      }
    }
    ` }
`

Button.defaultProps = {
  className: '',
  buttonContent: 'Check my rate',
  buttonHref: null,
  testID: null,
  color: 'flamingo',
  mobileColor: null,
  onClick: null,
}

Button.propTypes = {
  className: PropTypes.string,
  buttonContent: PropTypes.node,
  buttonHref: PropTypes.string,
  testID: PropTypes.string,
  color: PropTypes.string,
  mobileColor: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button
