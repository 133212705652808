import PropTypes from 'prop-types'
import Head from 'next/head'
import { useRouter } from 'next/router'

const SEO = (props) => {
  const { title, description, social } = props
  const { asPath } = useRouter()
  const path = asPath.split('?')[0]
  const isArticleDetail = (path || '').includes('/articles/')

  return (
    <Head>
      <title key="meta:title">{isArticleDetail ? 'Articles | ' : ''}{title}</title>
      <link rel="canonical" href={`${ process.env.NEXT_PUBLIC_HM_POINT_BREAK_URL }${ path }`} />

      <meta name="description" content={description} key="meta:description" />
      <meta name="keywords" content="Happy Money, Payoff Loan, Happiness" key="meta:keywords" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />

      {/* Facebook */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:url" href={`${ process.env.NEXT_PUBLIC_HM_POINT_BREAK_URL }${ path }`} />
      <meta property="og:type" content={isArticleDetail ? 'article' : 'website'} key="og:title" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} key="og:description" />
      <meta property="og:image" content={social?.url} />
      <meta property="og:image:alt" content={social?.alt} />

      {/* Twitter */}
      <meta name="twitter:creator" content="@happymoney" />
      <meta name="twitter:card" content={isArticleDetail ? 'article' : 'website'} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} key="twitter:description" />
      <meta name="twitter:image" content={social?.url} />
      <meta name="twitter:image:alt" content={social?.alt} />
    </Head>
  )
}

SEO.defaultProps = {
  title: 'Happy Money | Personal loans with your best interests at heart',
}

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  social: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
}

export default SEO
