import Cookies from 'js-cookie'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { v4 as uuidv4 } from 'uuid'

const ajsAnonymousId = Cookies.get('ajs_anonymous_id') || `point-break-${ uuidv4() }`
// initialize LaunchDarkly with either an anonymous context or the logged in user's context
const ldProviderOptions = {
  clientSideID: process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID,
  context: {
    kind: 'user',
    // Since point-break doesn't have memberId or lead.guid
    // we are using ajsAnonymousId instead
    key: ajsAnonymousId,
    // add custom user settings to LaunchDarkly. these can be used in
    // finding users and targeted percentage and rule based rollouts.
    ajsAnonymousId
  }
}

const LaunchDarklyProvider = withLDProvider(ldProviderOptions)

export default LaunchDarklyProvider
