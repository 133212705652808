
/**
 *
 * @param {string} str string to be serialized
 * @returns {string} A serialized url without: upper cases, white spaces, and special characters
 */
export const serializePressUrl = (str) => {
  return str.toLowerCase().replace(/\s/g, '-').replace(/['"\u0040\u0026\u2122\u00ae]/g, '')
}

/**
 * Build out a query string
 * @param  {Object} paramObject The key/value pairs you want in the URL
 * @returns {String} A URL with &key=value from the paramObject
 */
export const buildQueryString = (paramObject) => {
  const params = new URLSearchParams(paramObject)

  const keysForDel = []
  params.forEach((value, key) => {
    if (value === '' || value === 'undefined') {
      keysForDel.push(key)
    }
  })

  keysForDel.forEach((key) => {
    params.delete(key)
  })

  return params.toString()
}

export default {
  serializePressUrl,
  buildQueryString,
}

