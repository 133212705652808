import { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

export const GlobalContext = createContext({})

export const GlobalProvider = (props) => {
  const { children, value } = props

  const [global] = useState({ ...value })

  return <GlobalContext.Provider value={[global]}>{children}</GlobalContext.Provider>
}

export const useGlobal = () => useContext(GlobalContext)

GlobalProvider.defaultProps = {
  value: null,
}

GlobalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  value: PropTypes.objectOf(PropTypes.any),
}

export default {
  GlobalProvider,
  GlobalContext
}
