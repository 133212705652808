import { fluidRange } from 'polished'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Text = (props) => {
  const { children, className, size, testID } = props

  return (
    <TextTag
      className={className}
      data-testid={testID}
      size={size}
    >
      {children}
    </TextTag>
  )
}

const TextTag = styled.p`
  font-family: ${ ({ theme, size }) => `${ theme.text[size].fontFamily }` };
  line-height: ${ ({ theme, size }) => theme.text[size].lineHeight };
  letter-spacing: ${ ({ theme, size }) => `${ theme.text[size].letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.text[size].fontSizeMin }px`,
        toSize: `${ theme.text[size].fontSizeMax }px`
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`
    ) }
`

Text.defaultProps = {
  children: '',
  className: '',
  size: 'large',
  testID: 'text'
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['xlarge', 'large', 'button', 'eyebrow', 'small', 'buttonEyebrow', 'xsmall']),
  testID: PropTypes.string
}

export default Text
